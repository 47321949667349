import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { NodeViewWrapper } from "@tiptap/react";
import { EditorBubbleItem, EditorInstance } from "novel";
import {
    AlignCenter,
    AlignLeft,
    AlignRight,
    BoldIcon,
    Check,
    ChevronDown,
    Footprints,
    Heading1,
    Heading2,
    Heading3,
    ItalicIcon,
    LucideIcon,
    Settings,
    SlidersHorizontal,
    TextIcon,
    UnderlineIcon,
} from "lucide-react";
import React from "react";
import { ButtonColorSelector } from "../selectors/button-color-selector";
import { ButtonLinkSelector } from "../selectors/button-link-selector";

export interface ButtonComponentProps {
    label: string;
    href: string;
    align: "left" | "center" | "right";
    size: "text-sm" | "text-md" | "text-xl" | "text-3xl" | "text-5xl";
    bold: boolean;
    italic: boolean;
    underline: boolean;
    backgroundColor: string;
    textColor: string;
}

interface SelectorItem {
    name: string;
    icon: LucideIcon;
    command: (updateAttributes: any, props: ButtonComponentProps) => void;
    isActive: (props: ButtonComponentProps) => boolean;
}

const items: SelectorItem[] = [
    {
        name: "Text",
        icon: TextIcon,
        command: (updateAttributes: any) => {
            updateAttributes({
                size: "text-md",
            });
        },
        isActive: (props) => {
            return props.size === "text-md";
        },
    },
    {
        name: "Heading 1",
        icon: Heading1,
        command: (updateAttributes: any) => {
            updateAttributes({
                size: "text-5xl",
            });
        },
        isActive: (props) => {
            return props.size === "text-5xl";
        },
    },
    {
        name: "Heading 2",
        icon: Heading2,
        command: (updateAttributes: any) => {
            updateAttributes({
                size: "text-3xl",
            });
        },
        isActive: (props) => {
            return props.size === "text-3xl";
        },
    },
    {
        name: "Heading 3",
        icon: Heading3,
        command: (updateAttributes: any) => {
            updateAttributes({
                size: "text-xl",
            });
        },
        isActive: (props) => {
            return props.size === "text-xl";
        },
    },
    {
        name: "Footer",
        icon: Footprints,
        command: (updateAttributes: any) => {
            updateAttributes({
                size: "text-sm",
            });
        },
        isActive: (props) => {
            return props.size === "text-sm";
        },
    },
];

const styleItems: SelectorItem[] = [
    {
        name: "bold",
        isActive: (props) => props.bold,
        command: (updateAttributes, props) => {
            updateAttributes({
                bold: !props.bold,
            });
        },
        icon: BoldIcon,
    },
    {
        name: "italic",
        isActive: (props) => props.italic,
        command: (updateAttributes, props) => {
            updateAttributes({
                italic: !props.italic,
            });
        },
        icon: ItalicIcon,
    },
    {
        name: "underline",
        isActive: (props) => props.underline,
        command: (updateAttributes, props) => {
            updateAttributes({
                underline: !props.underline,
            });
        },
        icon: UnderlineIcon,
    },
    // {
    //     name: "strike",
    //     isActive: (editor) => editor!.isActive("strike"),
    //     command: (editor) => editor!.chain().focus().toggleStrike().run(),
    //     icon: StrikethroughIcon,
    // },
];
const alignItems: SelectorItem[] = [
    {
        name: "left",
        isActive: (props) => props.align === "left",
        command: (updateAttributes) =>
            updateAttributes({
                align: "left",
            }),
        icon: AlignLeft,
    },
    {
        name: "right",
        isActive: (props) => props.align === "right",
        command: (updateAttributes) =>
            updateAttributes({
                align: "right",
            }),
        icon: AlignRight,
    },
    {
        name: "center",
        isActive: (props) => props.align === "center",
        command: (updateAttributes) =>
            updateAttributes({
                align: "center",
            }),
        icon: AlignCenter,
    },
];

export default (props: any) => {
    const { node, updateAttributes, editor } = props;
    const { isEditable } = editor as EditorInstance;
    const { attrs }: { attrs: ButtonComponentProps } = node;

    const activeItem = items.find((item) => item.isActive(attrs)) || items[0];

    const activeAlignIndex =
        alignItems.findIndex((align) => align.isActive(attrs)) || 0;

    const activeAlign = alignItems[activeAlignIndex];

    const [open, onOpenChange] = React.useState(false);

    return (
        <NodeViewWrapper className="button-component">
            <div
                className="flex flex-row gap-2 items-center my-2"
                style={{
                    justifyContent:
                        attrs.align === "left"
                            ? "flex-start"
                            : attrs.align === "center"
                            ? "center"
                            : "flex-end",
                }}
            >
                <a href={attrs.href} target="_blank" rel="noopener noreferrer">
                    <Button
                        className={`${attrs.size} ${
                            attrs.bold ? "font-bold" : ""
                        } ${attrs.italic ? "italic" : ""} ${
                            attrs.underline ? "underline" : ""
                        }`}
                        style={{
                            backgroundColor: attrs.backgroundColor,
                            color: attrs.textColor,
                        }}
                        size="novel"
                    >
                        {attrs.label}
                    </Button>
                </a>

                {isEditable && (
                    <Popover open={open} onOpenChange={onOpenChange}>
                        <PopoverTrigger>
                            <Button
                                size="icon"
                                variant="link"
                                className="h-8 w-8 text-gray-400"
                                onClick={() => {}}
                            >
                                <Settings size={18} />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            side={"top"}
                            className="p-0 flex flex-row h-9 w-full overflow-x-scroll mb-2 rounded-md bg-background shadow-xl"
                        >
                            <ButtonLinkSelector
                                currentLink={attrs.href}
                                updateLink={(href) => {
                                    updateAttributes({
                                        href,
                                    });
                                }}
                                currentLabel={attrs.label}
                                updateLabel={(label) => {
                                    updateAttributes({
                                        label,
                                    });
                                }}
                            />
                            <Separator orientation="vertical" />
                            <Popover>
                                <PopoverTrigger
                                    asChild
                                    className="gap-2 rounded-none border-none hover:bg-accent focus:ring-0"
                                >
                                    <Button
                                        size="sm"
                                        variant="ghost"
                                        // className="gap-2"
                                    >
                                        <span className="whitespace-nowrap text-sm">
                                            {activeItem.name}
                                        </span>
                                        <ChevronDown className="h-4 w-4" />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    sideOffset={5}
                                    align="start"
                                    className="w-48 p-1"
                                >
                                    {items.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                item.command(
                                                    updateAttributes,
                                                    attrs
                                                );
                                                onOpenChange(false);
                                            }}
                                            className="flex cursor-pointer items-center justify-between rounded-sm px-2 py-1 text-sm hover:bg-accent"
                                        >
                                            <div className="flex items-center space-x-2">
                                                <div className="rounded-sm border p-1">
                                                    <item.icon className="h-3 w-3" />
                                                </div>
                                                <span>{item.name}</span>
                                            </div>
                                            {activeItem.name === item.name && (
                                                <Check className="h-4 w-4" />
                                            )}
                                        </div>
                                    ))}
                                </PopoverContent>
                            </Popover>
                            <Separator orientation="vertical" />
                            <div className="flex">
                                {styleItems.map((item, index) => (
                                    <EditorBubbleItem
                                        key={index}
                                        onSelect={() => {
                                            item.command(
                                                updateAttributes,
                                                attrs
                                            );
                                        }}
                                    >
                                        <Button
                                            size="sm"
                                            className="rounded-none"
                                            variant="ghost"
                                        >
                                            <item.icon
                                                className={cn("h-4 w-4", {
                                                    "text-blue-500":
                                                        item.isActive(attrs),
                                                })}
                                            />
                                        </Button>
                                    </EditorBubbleItem>
                                ))}
                                <EditorBubbleItem
                                    onSelect={() => {
                                        alignItems[
                                            (activeAlignIndex + 1) % 3
                                        ].command(updateAttributes, attrs);
                                    }}
                                >
                                    <Button
                                        size="sm"
                                        className="rounded-none"
                                        variant="ghost"
                                    >
                                        {!!activeAlign && (
                                            <activeAlign.icon
                                                className={cn("h-4 w-4")}
                                            />
                                        )}
                                    </Button>
                                </EditorBubbleItem>
                            </div>
                            <Separator orientation="vertical" />

                            <Separator orientation="vertical" />
                            <ButtonColorSelector
                                currentColor={attrs.textColor}
                                currentHighlight={attrs.backgroundColor}
                                updateColor={(color) => {
                                    updateAttributes({
                                        textColor: color,
                                    });
                                }}
                                updateHighlight={(color) => {
                                    updateAttributes({
                                        backgroundColor: color,
                                    });
                                }}
                            />
                        </PopoverContent>
                    </Popover>
                )}
            </div>
        </NodeViewWrapper>
    );
};
