import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { NodeViewWrapper } from "@tiptap/react";
import { useChat } from "ai/react";
import { Sparkle } from "lucide-react";
import React from "react";
import { useCompletion } from "ai/react";
import { Label } from "@/components/ui/label";

interface AIComponentProps {
    prompt: string;
    size: "text-sm" | "text-md" | "text-xl" | "text-3xl" | "text-5xl";
}

export default (props: any) => {
    const { updateAttributes, node, editor } = props;
    const { attrs }: { attrs: AIComponentProps } = node;
    const [prompt, setPrompt] = React.useState(attrs.prompt);
    const [chat, setChat] = React.useState<string>("");

    React.useEffect(() => {
        setPrompt(attrs.prompt);
    }, [attrs.prompt]);

    const generateSample1 = async () => {
        const response = await fetch("/api/generate-sample", {
            method: "POST",
            body: JSON.stringify({
                newsletter_state: editor.getHTML(),
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.ok) {
            const streamData = response.body;

            if (streamData) {
                const reader = streamData.getReader();
                const decoder = new TextDecoder();
                let done = false;
                let buffer = "";

                while (!done) {
                    const { value, done: doneReading } = await reader.read();
                    done = doneReading;
                    buffer += decoder.decode(value);
                    setChat(buffer);
                }
            }
        }
    };

    // console.log(chat);

    // const { complete, completion, isLoading } = useCompletion({
    //     api: "/api/generate-sample",
    //     body: {
    //         newsletter_state: editor.getHTML(),
    //     },
    // });

    // console.log(completion);

    // const generateSample = React.useCallback(async () => {
    //     await complete(prompt);
    // }, [complete]);

    return (
        <NodeViewWrapper className="aitext-component">
            <div className="border-2 border-black bg-gray-100 rounded-md pt-8 pb-4 px-4 relative">
                <span className="text-xs font-medium absolute text-white bg-black px-3 py-1 top-0 left-4 rounded-tr-none rounded-tl-none rounded-lg">
                    AI TEXT
                </span>
                <div className="flex flex-col gap-2">
                    <Textarea
                        rows={3}
                        value={prompt}
                        onChange={(e) => {
                            setPrompt(e.target.value);
                            updateAttributes({ prompt: e.target.value });
                        }}
                        placeholder="Enter what you would like this section to be about"
                        className="w-full focus-visible:ring-0 focus-visible:ring-ring border-gray-700 text-black border-2 bg-white rounded-md p-2 mt-2"
                    />
                    <Button
                        variant="dark"
                        className="text-white w-fit self-end gap-2"
                        size="sm"
                        onClick={generateSample1}
                        // disabled={isLoading}
                    >
                        <Sparkle size={14} />{" "}
                        {chat ? "Regenerate sample" : "Generate sample"}
                    </Button>
                </div>
                <p className="text-sm">{chat}</p>
                {/* {chat && (
                    <p className="text-xs absolute -bottom-2 left-1">
                        This block will generate a similar text block for every
                        edition of your newsletter.
                    </p>
                )} */}
            </div>
        </NodeViewWrapper>
    );
};
