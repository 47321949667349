import { cn } from "@/lib/utils";
import { useEditor } from "novel";
import { ChevronDown, ChevronsDownUp, Trash } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import { PopoverContent } from "@/components/tailwind/ui/popover";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { X } from "@phosphor-icons/react/dist/ssr/X";
import { Check } from "@phosphor-icons/react/dist/ssr/Check";

export function isValidUrl(url: string) {
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
}
export function getUrlFromString(str: string) {
    if (isValidUrl(str)) return str;
    try {
        if (str.includes(".") && !str.includes(" ")) {
            return new URL(`https://${str}`).toString();
        }
    } catch (e) {
        return null;
    }
}
const ICON_SIZE = 16;
interface LinkSelectorProps {
    updateLink: (url: string) => void;
    updateLabel: (label: string) => void;
    currentLabel: string;
    currentLink: string;
}

export const ButtonLinkSelector: React.FC<LinkSelectorProps> = ({
    updateLabel,
    updateLink,
    currentLabel,
    currentLink,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [label, setLabel] = useState(currentLabel);
    const [url, setUrl] = useState(currentLink);

    // Autofocus on input by default
    useEffect(() => {
        inputRef.current && inputRef.current?.focus();
        setLabel(currentLabel);
        setUrl(currentLink);
    }, [currentLabel, currentLink]);

    const [open, onOpenChange] = useState(false);

    return (
        <Popover modal={true} open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    size="sm"
                    variant="ghost"
                    className="gap-2 rounded-none border-none"
                >
                    <p
                        className={cn("text-sm", {
                            "text-blue-500": !!url,
                        })}
                    >
                        Settings
                    </p>
                    <p
                        className={cn("text-base", {
                            "text-blue-500": !!url,
                        })}
                    >
                        <ChevronDown className="h-4 w-4" />
                    </p>
                </Button>
            </PopoverTrigger>
            <PopoverContent align="start" className="w-fit p-0" sideOffset={10}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        const sanitizedUrl = getUrlFromString(url);
                        const sanitizedLabel = label.trim();
                        updateLink(sanitizedUrl ?? "");
                        updateLabel(sanitizedLabel);
                        onOpenChange(false);
                    }}
                    className="flex flex-col gap-3 p-4 relative"
                >
                    <div className="absolute flex flex-row gap-1 items-center top-2 right-2">
                        <Button
                            variant="ghost"
                            className="h-7 w-7"
                            size="icon"
                            onClick={() => {
                                onOpenChange(false);
                            }}
                        >
                            <X weight="bold" size={ICON_SIZE} />
                        </Button>
                        <Button
                            variant="dark"
                            type="submit"
                            className="h-7 w-7"
                            size="icon"
                        >
                            <Check weight="bold" size={ICON_SIZE} />
                        </Button>
                    </div>
                    <div className="space-y-2">
                        <h4 className="font-medium leading-none">
                            Button settings
                        </h4>
                        <p className="text-sm text-muted-foreground">
                            Set the CTA settings for this button.
                        </p>
                    </div>
                    <Input
                        ref={inputRef}
                        type="text"
                        placeholder="Paste a URL"
                        // className="flex-1 bg-background p-1 text-sm outline-none"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <Input
                        type="text"
                        placeholder="Button text"
                        // className="flex-1 bg-background p-1 text-sm outline-none"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                    />
                </form>
            </PopoverContent>
        </Popover>
    );
};
