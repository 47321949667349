import { Check, ChevronDown } from "lucide-react";
import { useState, type Dispatch, type SetStateAction } from "react";
import { EditorBubbleItem, useEditor } from "novel";

import {
    PopoverTrigger,
    Popover,
    PopoverContent,
} from "@/components/tailwind/ui/popover";
import { Button } from "@/components/tailwind/ui/button";
export interface BubbleColorMenuItem {
    name: string;
    color: string;
}

const TEXT_COLORS: BubbleColorMenuItem[] = [
    {
        name: "Default",
        color: "var(--novel-black)",
    },
    {
        name: "Purple",
        color: "#9333EA",
    },
    {
        name: "Red",
        color: "#E00000",
    },
    {
        name: "Yellow",
        color: "#EAB308",
    },
    {
        name: "Blue",
        color: "#2563EB",
    },
    {
        name: "Green",
        color: "#008A00",
    },
    {
        name: "Orange",
        color: "#FFA500",
    },
    {
        name: "Pink",
        color: "#BA4081",
    },
    {
        name: "Gray",
        color: "#A8A29E",
    },
];

const HIGHLIGHT_COLORS: BubbleColorMenuItem[] = [
    {
        name: "Default",
        color: "var(--novel-highlight-default)",
    },
    {
        name: "Purple",
        color: "var(--novel-highlight-purple)",
    },
    {
        name: "Red",
        color: "var(--novel-highlight-red)",
    },
    {
        name: "Yellow",
        color: "var(--novel-highlight-yellow)",
    },
    {
        name: "Blue",
        color: "var(--novel-highlight-blue)",
    },
    {
        name: "Green",
        color: "var(--novel-highlight-green)",
    },
    {
        name: "Orange",
        color: "var(--novel-highlight-orange)",
    },
    {
        name: "Pink",
        color: "var(--novel-highlight-pink)",
    },
    {
        name: "Gray",
        color: "var(--novel-highlight-gray)",
    },
];

interface ColorSelectorProps {
    updateColor: (color: string) => void;
    updateHighlight: (color: string) => void;
    currentColor: string;
    currentHighlight: string;
}

export const ButtonColorSelector = ({
    currentColor,
    currentHighlight,
    updateColor,
    updateHighlight,
}: ColorSelectorProps) => {
    const [open, onOpenChange] = useState(false);
    const activeColorItem = TEXT_COLORS.find(
        ({ color }) => currentColor === color
    );

    const activeHighlightItem = HIGHLIGHT_COLORS.find(
        ({ color }) => currentHighlight === color
    );

    return (
        <Popover modal={true} open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    size="sm"
                    className="gap-2 rounded-none"
                    variant="ghost"
                >
                    <span
                        className="rounded-sm px-1"
                        style={{
                            color: activeColorItem?.color,
                            backgroundColor: activeHighlightItem?.color,
                        }}
                    >
                        A
                    </span>
                    <ChevronDown className="h-4 w-4" />
                </Button>
            </PopoverTrigger>

            <PopoverContent
                sideOffset={5}
                className="my-1 flex max-h-80 w-48 flex-col overflow-hidden overflow-y-auto rounded border p-1 shadow-xl "
                align="start"
            >
                <div className="flex flex-col">
                    <div className="my-1 px-2 text-sm font-semibold text-muted-foreground">
                        Color
                    </div>
                    {TEXT_COLORS.map(({ name, color }, index) => (
                        <EditorBubbleItem
                            key={index}
                            onSelect={() => {
                                updateColor(color);
                            }}
                            className="flex cursor-pointer items-center justify-between px-2 py-1 text-sm hover:bg-accent"
                        >
                            <div className="flex items-center gap-2">
                                <div
                                    className="rounded-sm border px-2 py-px font-medium"
                                    style={{ color }}
                                >
                                    A
                                </div>
                                <span>{name}</span>
                            </div>
                            {activeColorItem?.color === color && (
                                <Check className="h-4 w-4" />
                            )}
                        </EditorBubbleItem>
                    ))}
                </div>
                <div>
                    <div className="my-1 px-2 text-sm font-semibold text-muted-foreground">
                        Background
                    </div>
                    {HIGHLIGHT_COLORS.map(({ name, color }, index) => (
                        <EditorBubbleItem
                            key={index}
                            onSelect={() => {
                                updateHighlight(color);
                            }}
                            className="flex cursor-pointer items-center justify-between px-2 py-1 text-sm hover:bg-accent"
                        >
                            <div className="flex items-center gap-2">
                                <div
                                    className="rounded-sm border px-2 py-px font-medium"
                                    style={{ backgroundColor: color }}
                                >
                                    A
                                </div>
                                <span>{name}</span>
                            </div>
                            {activeHighlightItem?.color === color && (
                                <Check className="h-4 w-4" />
                            )}
                        </EditorBubbleItem>
                    ))}
                </div>
            </PopoverContent>
        </Popover>
    );
};
