// "use client";

import { mergeAttributes, Node } from "@tiptap/core";

export interface SmallTextOptions {
    HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        smallText: {
            /**
             * Toggle a smallText
             */
            setSmallText: () => ReturnType;
            /**
             * Toggle a smallText
             */
            toggleSmallText: () => ReturnType;
        };
    }
}

export const SmallText = Node.create<SmallTextOptions>({
    name: "smallText",

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    group: "block",
    priority: 1000,
    content: "inline*",

    parseHTML() {
        return [
            {
                tag: "p.small-text", // This targets <p> elements with a "small-text" class.
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        // Custom styles for small text. You can adjust the font-size as needed.
        const customStyle = { style: "font-size: 0.7em;" };
        return [
            "p",
            mergeAttributes(
                this.options.HTMLAttributes,
                HTMLAttributes,
                customStyle
            ),
            0,
        ];
    },

    addCommands() {
        return {
            setSmallText:
                () =>
                ({ commands }) => {
                    return commands.setNode(this.name, { class: "small-text" }); // Optionally set a class or other attributes.
                },
            toggleSmallText:
                () =>
                ({ commands }: { commands: any }) => {
                    return commands.toggleNode(this.name, {
                        class: "small-text",
                    }); // Optionally set a class or other attributes.
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            "Mod-Alt-1": () => this.editor.commands.setSmallText(), // Change shortcut as needed.
        };
    },
});

export default SmallText;
