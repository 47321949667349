import { Editor } from "@tiptap/react";
import { useEditor } from "novel";
import { useEffect } from "react";

interface LiftEditorStateProps {
    onMountEditor: (editor: Editor) => void;
}

const LiftEditorState: React.FC<LiftEditorStateProps> = ({ onMountEditor }) => {
    const { editor } = useEditor();
    if (!editor) return null;

    useEffect(() => {
        if (editor) {
            onMountEditor(editor);
        }
    }, [editor]);

    return null;
};

export default LiftEditorState;
