import { Laptop2, Smartphone } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import { useMediaQuery } from "@/hooks/use-media-query";

interface PreviewTabsProps {
    tab: PreviewLayoutTypes;
    setTab: (tab: PreviewLayoutTypes) => void;
}

const PreviewTabs: React.FC<PreviewTabsProps> = ({ tab, setTab }) => {
    const isDesktop = useMediaQuery("(min-width: 768px)");

    if (!isDesktop) {
        return null;
    }

    return (
        <Tabs
            value={tab}
            onValueChange={(tab: string) => setTab(tab as PreviewLayoutTypes)}
        >
            <div className="flex flex-row gap-4 items-center">
                <TabsList className="grid w-fit grid-cols-2 mb-3">
                    <TabsTrigger value="web">
                        <Laptop2 size={16} />
                    </TabsTrigger>
                    <TabsTrigger value="mobile">
                        <Smartphone size={16} />
                    </TabsTrigger>
                </TabsList>
            </div>
        </Tabs>
    );
};

export default PreviewTabs;
