import { Node } from "@tiptap/core";

const Section = Node.create({
    name: "section",
    content: "block+",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            bgColor: {
                default: null,
                parseHTML: (element) => element.style.backgroundColor,
                renderHTML: (attributes) => {
                    return {
                        style: `background-color: ${attributes.bgColor}`,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-type="section"]',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["div", { ...HTMLAttributes, "data-type": "section" }, 0];
    },
});

export default Section;
