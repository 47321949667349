import {
    TiptapImage,
    TiptapLink,
    UpdatedImage,
    TaskList,
    TaskItem,
    HorizontalRule,
    StarterKit,
    Placeholder,
    AIHighlight,
} from "novel/extensions";
import { UploadImagesPlugin } from "novel/plugins";
import TextAlign from "@tiptap/extension-text-align";
import Typography from "@tiptap/extension-typography";

import { cx } from "class-variance-authority";
import SmallText from "./tiptap/SmallText";
import { slashCommand } from "./slash-command";
import ButtonComponentTiptap from "./tiptap/ButtonComponentTiptap";
import AIComponentTiptap from "./tiptap/AIComponentTiptap";
import Highlight from "@tiptap/extension-highlight";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import SectionComponentTiptap from "./tiptap/SectionComponentTiptap";
import Section from "./tiptap/SectionComponentTiptap";
import SectionNodeView from "./tiptap/SectionComponentReact";

//TODO I am using cx here to get tailwind autocomplete working, idk if someone else can write a regex to just capture the class key in objects
const aiHighlight = AIHighlight;
//You can overwrite the placeholder with your own configuration
const placeholder = Placeholder;
const tiptapLink = TiptapLink.configure({
    HTMLAttributes: {
        class: cx(
            "text-muted-foreground underline underline-offset-[3px] hover:text-primary transition-colors cursor-pointer"
        ),
    },
});

const textAlign = TextAlign.configure({
    types: ["heading", "paragraph", "image", "code", "smallText", "button"],
});

const section = Section.configure({
    nodeView: SectionNodeView,
});

const tiptapImage = TiptapImage.extend({
    addProseMirrorPlugins() {
        return [
            UploadImagesPlugin({
                imageClass: cx("opacity-40 rounded-lg border border-stone-200"),
            }),
        ];
    },
}).configure({
    allowBase64: true,
    HTMLAttributes: {
        class: cx("rounded-lg border border-muted"),
    },
});

const updatedImage = UpdatedImage.configure({
    HTMLAttributes: {
        class: cx("rounded-lg border border-muted"),
    },
});

const taskList = TaskList.configure({
    HTMLAttributes: {
        class: cx("not-prose pl-2 "),
    },
});
const taskItem = TaskItem.configure({
    HTMLAttributes: {
        class: cx("flex gap-2 items-start my-4"),
    },
    nested: true,
});

const horizontalRule = HorizontalRule.configure({
    HTMLAttributes: {
        class: cx("mt-4 mb-6 mx-8 border-t-2 rounded-sm border-gray-400"),
    },
});

const starterKit = StarterKit.configure({
    bulletList: {
        HTMLAttributes: {
            class: cx("list-disc list-outside leading-3 -mt-2"),
        },
    },
    orderedList: {
        HTMLAttributes: {
            class: cx("list-decimal list-outside leading-3 -mt-2"),
        },
    },
    listItem: {
        HTMLAttributes: {
            class: cx("leading-normal -mb-2"),
        },
    },
    blockquote: {
        HTMLAttributes: {
            class: cx("border-l-4 border-gray-400"),
        },
    },
    codeBlock: {
        HTMLAttributes: {
            class: cx(
                "rounded-md bg-muted text-muted-foreground border p-5 font-mono font-medium"
            ),
        },
    },
    code: {
        HTMLAttributes: {
            class: cx("rounded-md bg-muted  px-1.5 py-1 font-mono font-medium"),
            spellcheck: "false",
        },
    },
    horizontalRule: false,
    dropcursor: {
        color: "#DBEAFE",
        width: 4,
    },
    gapcursor: false,
});

const BgHighlight = Highlight.configure({ multicolor: true });

export const defaultExtensions = [
    starterKit,
    placeholder,
    tiptapLink,
    tiptapImage,
    updatedImage,
    taskList,
    taskItem,
    horizontalRule,
    aiHighlight,
    Typography,
    textAlign,
    SmallText,
    ButtonComponentTiptap,
    AIComponentTiptap,
    section,
    slashCommand,
    BgHighlight,
    TextStyle,
    Underline,
];
