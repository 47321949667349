// "use client";

import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import Component from "./AIComponentReact";

export default Node.create({
    name: "aiTextComponent",

    group: "block",
    // content: "inline*",
    atom: true,
    selectable: true,

    addAttributes() {
        return {
            prompt: {
                default: "",
            },
            size: {
                default: "text-md",
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "aitext-component",
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ["aitext-component", mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component);
    },
});
