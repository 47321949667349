import { insertAsset } from "@/supabase/assets";
import { createImageUpload } from "novel/plugins";
import { toast } from "sonner";

const onUpload = (file: File) => {
    const promise = fetch("/api/upload-image", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            filename: file.name,
            contentType: file.type,
        }),
        // headers: {
        //     "content-type": file?.type || "application/octet-stream",
        //     "x-vercel-filename": file?.name || "image.png",
        // },
        // body: file,
    });

    return new Promise((resolve) => {
        toast.promise(
            promise.then(async (res) => {
                // Successfully uploaded image
                if (res.status === 200) {
                    const { url, fields } = await res.json();

                    // console.log("fields, url", fields, url);

                    const formData = new FormData();
                    Object.entries(fields).forEach(([key, value]) => {
                        formData.append(key, value as string);
                    });
                    formData.append("file", file);

                    await fetch(url, {
                        method: "POST",
                        body: formData,
                    });

                    const imageUrl = url + fields.key;
                    // preload the image
                    let image = new Image();
                    image.src = imageUrl;
                    image.onload = () => {
                        resolve(imageUrl);
                    };

                    // await insertAsset(supabase, {
                    //     name: file.name,
                    //     src: imageUrl,
                    //     type: "images",
                    //     newsletter_id: newsletter.id,
                    //     creator_id: newsletter.creator_id,
                    // })

                    // No blob store configured
                } else if (res.status === 401) {
                    resolve(file);
                    throw new Error(
                        "`BLOB_READ_WRITE_TOKEN` environment variable not found, reading image locally instead."
                    );
                    // Unknown error
                } else {
                    throw new Error(`Error uploading image. Please try again.`);
                }
            }),
            {
                loading: "Uploading image...",
                success: "Image uploaded successfully.",
                error: (e) => e.message,
            }
        );
    });
};

export const uploadFn = createImageUpload({
    onUpload,
    validateFn: (file) => {
        if (!file.type.includes("image/")) {
            toast.error("File type not supported.");
            return false;
        } else if (file.size / 1024 / 1024 > 20) {
            toast.error("File size too big (max 20MB).");
            return false;
        }
        return true;
    },
});
