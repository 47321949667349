import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import React from "react";

const SectionComponent = (props: any) => {
    const { node, updateAttributes, extension } = props;
    const style = { backgroundColor: node.attrs.bgColor || "transparent" };

    const onChangeColor = (event: any) => {
        updateAttributes({ bgColor: event.target.value });
    };

    return (
        <NodeViewWrapper style={style}>
            <div contentEditable={false} style={{ padding: "10px" }}>
                <label>Background Color: </label>
                <input
                    type="color"
                    value={node.attrs.bgColor || "#ffffff"}
                    onChange={onChangeColor}
                />
            </div>
            <div contentEditable>{extension.options.content}</div>
        </NodeViewWrapper>
    );
};

const SectionNodeView = ReactNodeViewRenderer(SectionComponent);

export default SectionNodeView;
