// "use client";

import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Component from "./ButtonComponentReact";

function getStyles(
    size: "text-sm" | "text-md" | "text-xl" | "text-3xl" | "text-5xl"
) {
    let fontSize, lineHeight;

    switch (size) {
        case "text-sm":
            fontSize = "0.875rem"; // 14px
            lineHeight = "1.25rem"; // 20px
            break;
        case "text-md":
            fontSize = "1rem"; // 16px
            lineHeight = "1.5rem"; // 24px
            break;
        case "text-xl":
            fontSize = "1.25rem"; // 20px
            lineHeight = "1.75rem"; // 28px
            break;
        case "text-3xl":
            fontSize = "1.875rem"; // 30px
            lineHeight = "2.25rem"; // 36px
            break;
        case "text-5xl":
            fontSize = "3rem"; // 48px
            lineHeight = "1";
            break;
        default:
            fontSize = "1rem"; // 16px
            lineHeight = "1.5rem"; // 24px
    }

    return { fontSize, lineHeight };
}

export default Node.create({
    name: "buttonComponent",

    group: "block",
    // content: "inline*",
    atom: true,
    selectable: true,

    addAttributes() {
        return {
            align: {
                default: "center",
            },
            href: {
                default: "https://example.com",
            },
            label: {
                default: "Click CTA",
            },
            size: {
                default: "text-sm",
            },
            bold: {
                default: true,
            },
            italic: {
                default: false,
            },
            underline: {
                default: false,
            },
            backgroundColor: {
                default: "#000",
            },
            textColor: {
                default: "#fff",
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "button-component",
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        let {
            label,
            href,
            align,
            size,
            bold,
            italic,
            underline,
            backgroundColor,
            textColor,
        } = node.attrs;

        let divStyle = `
            display: flex;
            flex-direction: row;
            gap: 2px;
            align-items: center;
            margin: 2px 0;
            width: 100%;
            align-self: ${align};
            justify-content: ${
                align === "left"
                    ? "flex-start"
                    : align === "center"
                    ? "center"
                    : "flex-end"
            };
        `;

        let buttonStyle = `
            background-color: ${backgroundColor};
            color: ${textColor};
            font-size: ${getStyles(size).fontSize};
            line-height: ${getStyles(size).lineHeight};
            font-weight: ${bold ? "bold" : "normal"};
            font-style: ${italic ? "italic" : "normal"};
            text-decoration: ${underline ? "underline" : "none"};
            padding: 10px;
        `;

        return [
            "div",
            mergeAttributes(HTMLAttributes, {
                class: "button-component",
                style: divStyle,
            }),
            [
                "a",
                {
                    href,
                    target: "_blank",
                    rel: "noopener noreferrer",
                },
                [
                    "button",
                    {
                        style: buttonStyle,
                        // class: "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
                    },
                    label,
                ],
            ],
        ];
    },
    renderText(props) {
        return (
            "BUTTON component with props: " + JSON.stringify(props.node.attrs)
        );
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component);
    },
});
