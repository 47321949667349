import { useEditor } from "novel";
import React, { Fragment, useEffect, type ReactNode } from "react";
import { Button } from "../ui/button";
import { AISelector } from "./ai-selector";
import Magic from "../ui/icons/magic";
import { removeAIHighlight } from "novel/extensions";
import EditorBubble from "./editor-bubble";

interface GenerativeMenuSwitchProps {
    children: ReactNode;
    open: boolean;
    onOpenChange: (open: boolean) => void;
}
const GenerativeMenuSwitch = ({
    children,
    open,
    onOpenChange,
}: GenerativeMenuSwitchProps) => {
    const { editor } = useEditor();

    if (!editor) return null;

    useEffect(() => {
        if (!open) removeAIHighlight(editor);
    }, [open]);
    return (
        <EditorBubble
            tippyOptions={{
                placement: open ? "bottom-start" : "top",
                onHidden: () => {
                    onOpenChange(false);
                    editor.chain().unsetHighlight().run();
                },
            }}
            className="flex max-w-fit overflow-x-scroll mb-2 rounded-md border border-muted bg-background shadow-xl"
        >
            {open && <AISelector open={open} onOpenChange={onOpenChange} />}
            {!open && (
                <Fragment>
                    <Button
                        className="gap-1 rounded-none text-purple-500"
                        variant="ghost"
                        onClick={() => onOpenChange(true)}
                        size="sm"
                    >
                        <Magic className="h-5 w-5" />
                        Ask AI
                    </Button>
                    {children}
                </Fragment>
            )}
        </EditorBubble>
    );
};

export default GenerativeMenuSwitch;
